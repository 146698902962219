
import { defineComponent, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import TimeTableBlock from '../../components/Partials/TimeTableBlock.vue';
import { useContent } from '../../composables/useContent';
import { useFeedback } from '../../composables/useFeedback';
import FeedBackForm from '../../components/FeedBackForm.vue';

export default defineComponent({
  components: { TimeTableBlock, FeedBackForm },
  name: 'TimeTablePage',
  setup() {
    const route = useRoute();
    const { getTimeTable } = useContent('timetable', { route });
    const { fetchSongRatings, getSongRatings } = useFeedback();
    const pageTitle = computed(() => getTimeTable.value.pageTitle);
    const introduction = computed(() => getTimeTable.value.introduction);
    const intermezzo = computed(() => getTimeTable.value.intermezzo);
    const firstSetlist = computed(() => getTimeTable.value.firstSetlist);
    const lastSetlist = computed(() => getTimeTable.value.lastSetList);
    onMounted(async () => {
      if (!getSongRatings.value) await fetchSongRatings();
    });

    return { pageTitle, introduction, firstSetlist, intermezzo, lastSetlist, getSongRatings };
  },
});
