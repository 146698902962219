
import { defineComponent, computed, ref, reactive } from 'vue';
import { useFeedback } from '../composables/useFeedback';
export default defineComponent({
  name: 'AppRatingItem',
  props: {
    song: {
      type: Object,
      default: () => ({}),
    },
    interactive: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const songId = computed(() => props.song.sys.id);
    const rateSelect = ref<HTMLElement[] | null>(null);
    const rating = reactive({
      icon: 'heart',
      range: 5,
      selected: null,
    });
    const modal = ref<HTMLDialogElement | null>(null);
    const { getSongRatings, resolveSongRating, setUserRating, isRatedSong } = useFeedback();

    const currentRating = computed(() => {
      return resolveSongRating(songId.value);
    });
    const ratedClass = computed(() => i => {
      const rate = currentRating?.value?.trunc;
      const per = currentRating?.value?.percentage;
      if ((rate && rate >= i) || (per && rate === i - 1)) return 'love';
    });

    const ratedMask = computed(() => i => {
      const per = currentRating?.value?.percentage;
      const trunc = currentRating?.value?.trunc;
      if (per && trunc && i === trunc + 1) {
        return 'url(#half)';
      }
    });

    const handleClick = () => {
      if (props.interactive && modal.value) {
        modal.value.showModal();
      }
    };
    const isRated = computed(() => isRatedSong(songId.value));
    const resetRating = () => {
      if (rateSelect.value) {
        rateSelect.value.forEach(el => {
          el.classList.remove('love');
        });
      }
    };
    const submitText = computed(() => (isRated.value ? 'aanpassen' : 'verstuur'));
    const handleRatingSelect = i => {
      resetRating();
      rateSelect.value?.forEach((el, index) => {
        if (index < i) {
          el.classList.add('love');
        }
        if (index === i - 1) {
          el.classList.add('pulse');
        }
      });
      rating.selected = i;
    };

    const handleSubmit = async () => {
      loading.value = true;
      if (rating.selected) {
        setUserRating({ id: songId.value, rating: rating.selected });
      }
      loading.value = false;
      modal.value?.close();
    };

    const handleModalClose = () => {
      if (modal.value?.open) {
        modal.value.close();
      }
      if (!isRated.value) resetRating();
    };

    return {
      loading,
      rating,
      currentRating,
      ratedClass,
      ratedMask,
      modal,
      rateSelect,
      isRated,
      submitText,
      handleClick,
      handleRatingSelect,
      handleSubmit,
      handleModalClose,
      getSongRatings,
    };
  },
});
