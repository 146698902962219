
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppSoundWave',
  props: {
    size: {
      type: [String, Boolean],
      default: false,
    },
    state: {
      type: String,
      default: 'paused',
    },
  },
  setup() {
    // do stuff
  },
});
