
// eslint-disable-next-line
import { ref, defineComponent, PropType, computed, nextTick, watchEffect } from 'vue';

interface ImageInterface {
  src: string;
  sizes?: string;
  alt?: string;
}

interface MediaInterface {
  landscape: ImageInterface;
  portrait?: ImageInterface;
  classes?: string;
}

const defaultmedia: MediaInterface = {
  portrait: {
    src: 'http://www.goedgebekt.com/core/wp-content/uploads/2016/11/gg_portrait.jpg',
  },
  landscape: {
    src: 'http://www.goedgebekt.com/core/wp-content/uploads/2016/12/GG_2016_1.jpg ',
    sizes: '(max-width: 3108px) 100vw, 3108px',
    alt: 'placeholder default',
  },
  classes: 'default-classes',
};

export default defineComponent({
  name: 'LazyImage',
  props: {
    sticky: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'image',
    },
    lazySettings: {
      type: Object || Boolean,
      default: () => {
        return {
          settings: {
            threshold: 0.1,
          },
          persist: false,
        };
      },
    },
    srcset: {
      type: Array,
      default: () => [375, 650, 900, 1500, 2020, 2740, 3840],
    },
    media: {
      type: Object as PropType<MediaInterface>,
      default: (): MediaInterface => defaultmedia,
    },
  },

  setup(props) {
    const root = ref<HTMLElement | null>(null);
    const landscape = ref<HTMLImageElement | null>(null);
    const loaded = ref(false);
    const getImgBySize = computed(
      () => (width: number) => `${props.media.landscape.src}?w=${width}`,
    );
    const getSrcset = computed(() => {
      let srcsetSizes;
      if (loaded.value && props.media.landscape.src) {
        srcsetSizes = props.srcset.map(
          width => `${props.media.landscape.src}?w=${width} ${width}w`,
        );
        srcsetSizes.join(',');
      }
      return srcsetSizes;
    });
    const setSrcset = () => {
      loaded.value = true;
    };

    // eslint-disable-next-line
    return { root, getSrcset, getImgBySize, setSrcset, landscape, loaded };
  },
});
