
import { defineComponent, computed, PropType } from 'vue';
import LazyImage from './LazyImage.vue';

export type MemberType = {
  name: string;
  range: string;
  avatar: string;
  motto?: string;
  bio?: string;
};

export default defineComponent({
  components: { LazyImage },
  props: {
    member: {
      type: Object as PropType<MemberType>,
      default: () => ({}),
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  setup(props, ctx) {
    const getMedia = computed(() => ({
      landscape: { src: `${props.member.avatar}?random=${props.idx}`, alt: props.member.name },
    }));
    return { getMedia };
  },
});
