
import {  defineComponent, computed } from "vue";
import { useContent } from "../../composables/useContent";
import AppMain from "../../components/AppMain.vue";

export default defineComponent({
  name: "ConcertPage",
  components: {
    AppMain,
  },

  setup() {
    const contentService = useContent("concert");
    const content = computed(() => contentService.getConcertpage.value);


    return { content  };
  },
});
