
import { defineComponent, computed, onMounted } from 'vue';
import { useContent } from '@/composables/useContent';
import { useFeedback } from '@/composables/useFeedback';
import RepertoirList from '@/components/RepertoirList.vue';

export default defineComponent({
  components: { RepertoirList },
  name:'FeedbackPage',
  setup() {
    const { getSongs, fetchSongs } = useContent();
    const { getFeedback, fetchFeedback, fetchSongRatings, resolveSongRating } = useFeedback();
    const songs = computed(() => getSongs.value?.filter(song => resolveSongRating(song.sys.id)));
    const feedback = computed(() => getFeedback.value);
    onMounted(async () => {
      await fetchSongs();
      await fetchSongRatings();
      await fetchFeedback();
    });
    return { songs, feedback, getSongs };
  },
});
