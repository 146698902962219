import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f823d52c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["srcset"]
const _hoisted_2 = ["sizes", "srcset", "alt"]
const _hoisted_3 = ["sizes", "src", "alt", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazyload = _resolveDirective("lazyload")!

  return _withDirectives((_openBlock(), _createElementBlock("figure", {
    role: "presentation",
    ref: "root",
    style: _normalizeStyle({ position: _ctx.sticky ? 'sticky' : undefined }),
    class: _normalizeClass(_ctx.loaded ? 'loaded' : false)
  }, [
    _createElementVNode("picture", null, [
      (_ctx.media.portrait)
        ? (_openBlock(), _createElementBlock("source", {
            key: 0,
            ref: "portrait",
            srcset: _ctx.media.portrait.src,
            type: "image/jpeg",
            media: "(orientation:portrait)"
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("source", {
        ref: "landscape",
        class: _normalizeClass(_ctx.media.classes),
        sizes: _ctx.media.landscape.sizes,
        loading: "lazy",
        srcset: _ctx.getSrcset,
        alt: _ctx.media.landscape.alt
      }, null, 10, _hoisted_2),
      _createElementVNode("img", {
        class: _normalizeClass(_ctx.media.classes),
        sizes: _ctx.media.landscape.sizes,
        src: _ctx.getImgBySize(20),
        alt: _ctx.media.landscape.alt,
        id: _ctx.id
      }, null, 10, _hoisted_3)
    ])
  ], 6)), [
    [_directive_lazyload, { ..._ctx.lazySettings, callback: _ctx.setSrcset }]
  ])
}