import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-290ab45d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "members" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MemberItem = _resolveComponent("MemberItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.heading), 1),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.memberLists, (memberList, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "member-list",
          key: index
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(memberList, (member, idx) => {
            return (_openBlock(), _createBlock(_component_MemberItem, {
              member: member,
              key: idx + index,
              idx: idx + Math.random(1, 9),
              class: _normalizeClass(_ctx.getClass(idx, member.range))
            }, null, 8, ["member", "idx", "class"]))
          }), 128))
        ]))
      }), 128))
    ])
  ], 64))
}