import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0004b01b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "setlist" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimeTableBlock = _resolveComponent("TimeTableBlock")!
  const _component_FeedBackForm = _resolveComponent("FeedBackForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TimeTableBlock, {
      cms: { pageTitle: _ctx.pageTitle, introduction: _ctx.introduction, firstSetlist: _ctx.firstSetlist, intermezzo: _ctx.intermezzo, lastSetlist: _ctx.lastSetlist }
    }, null, 8, ["cms"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FeedBackForm)
    ])
  ]))
}