import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-598778b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "member-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lazy_image = _resolveComponent("lazy-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_lazy_image, {
      media: _ctx.getMedia,
      class: "member-avatar",
      id: _ctx.member.name
    }, null, 8, ["media", "id"]),
    _createElementVNode("h4", null, _toDisplayString(_ctx.member.name), 1)
  ]))
}