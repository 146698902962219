
import { defineComponent, ref, onUpdated, onMounted } from 'vue';
import SetList from '../SetList.vue';
import ContentfulRichText from '../ContentfulRichText.vue';

// https://next--vue-dataset-demo.netlify.app/components/#props
export default defineComponent({
  name: 'TimeTableBlock',
  components: { SetList, ContentfulRichText },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup() {
    const soundwave = ref<HTMLElement | null>(null);
    const hints = ref<HTMLElement | null>(null);
    const showHints = ref(true);
    const hintDelay = ref(0);
    onUpdated(() => {
      if (soundwave.value) {
        const soundwaveElements = soundwave.value.getElementsByClassName('wave');
        const arrayFromElems = Array.from(soundwaveElements);
        arrayFromElems.forEach((el, idx) => {
          if (idx === 0) {
            el.classList.add('colorWave');
          }

          el.addEventListener('animationiteration', () => {
            const nextEl = arrayFromElems[idx + 1];
            nextEl.classList.add('colorWave');
          });
        });
      }
    });
    onMounted(() => {
      if (hints.value) {
        hints.value.addEventListener('animationiteration', () => {
          hintDelay.value++;
          if (hintDelay.value >= 5) showHints.value = false;
        });
      }
    });
    const handleLegend = () => {
      hintDelay.value = 0;
      showHints.value = true;
    };
    return { soundwave, hints, showHints, handleLegend };
  },
});
