
import { defineComponent, computed, PropType } from 'vue';

import MemberItem, { MemberType } from '@/components/MemberItem.vue';

export default defineComponent({
  components: { MemberItem },
  props: {
    members: {
      type: Array as PropType<MemberType[]>,
      default: () => {
        [];
      },
    },
  },
  setup(props) {
    const heading = computed(() => 'De leden');

    const classes = [' blend-pink', ' blend-red', ' blend-yellow', ' blend-green'];

    const memberLists = computed(() => {
      const separatedLists: Record<string, MemberType[]> = {};
      props.members.forEach(member => {
        const val = member.range;
        if (!(val in separatedLists)) {
          separatedLists[val] = [];
        }
        separatedLists[val].push(member);
      });
      return Object.values(separatedLists);
    });

    const getClass = (index: number, range: string) => {
      const classIndex = index % classes.length;
      return range + classes[classIndex];
    };
    return { heading, getClass, memberLists };
  },
});
