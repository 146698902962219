import { resolveComponent as _resolveComponent, createVNode as _createVNode, KeepAlive as _KeepAlive, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5719cc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppMain = _resolveComponent("AppMain")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        _createVNode(_component_AppMain, { content: _ctx.content }, null, 8, ["content"])
      ], 1024))
    ])
  ]))
}